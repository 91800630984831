
  import AeqAlertsSettings from '~/components/AeqAlertsSettings'

  const STEPS = {
    SCREENING: 1,
    CANDIDATE: 2,
    SETTINGS: 3,
    SUMMARY: 4
  }
  export default {
    name: `ClientNewScreeningPage`,
    components: { AeqAlertsSettings },
    middleware({ $gates, store, route, redirect }) {
      const path = route.path
      const urlWithoutLastSlash = path.replace(/\/$/, ``)
      const permissionFromUrl = store.getters[`menu/getPermissionFromUrl`](urlWithoutLastSlash)
      const hasPermission = permissionFromUrl ? $gates.hasPermission(permissionFromUrl) : false
      if (!hasPermission) {
        redirect(`/`)
      }
    },
    data() {
      return {
        STEPS,
        isInitialScreeningStep: true,
        screening_packages: [],
        screening_reasons_categories: [],
        screening_reasons: [],
        blurPhoneField: false,
        form: this.initialForm(),
        formFiles: {
          cv_files: undefined,
          other_files: undefined
        },
        rules: {
          reason_category: `required`,
          reason: `required`,
          dueDate: {
            after: {
              limit: this.$moment().add(6, `d`).format(`YYYY-MM-DD`)
            }
          },
          package: `required`,
          title: `required`,
          first_name: `required`,
          last_name: `required`,
          birthdate: {
            before: { limit: new Date(new Date().setFullYear(new Date().getFullYear() - 13)) },
            formatDate: true
          },
          lang: `required`,
          email: `required|email`,
          phone: `required|phoneNumber`,
          job_title: `required`,
          cost_center: `required`,
          comments: ``,
          start_date: `consistentDate|formatDate`,
          cv_required: `required`,
          other_files: ``
        },
        refresh: 0,
        dataClearAllDialog: false,
        reportDate: this.$t(`messages.na`),
        dueDateDefault: [7, 14, 21, 28],
        disabledButtonConfirmOrder: false,
        defaultAlerts: {
          selected_alerts: {},
          custom_alerts: false,
          update: false
        },
        defaultTranparency: {
          progress: false,
          result: false,
          update: false
        },
        defaultScreeningDeletionData: {
          period: 90,
          update: false
        }
      }
    },
    async fetch() {
      const [packages, options] = await Promise.all([this.getPackages(), this.getOptions()])

      if (!!packages) {
        this.screening_packages = [...packages]
        await this.updateRules()
      }

      if (!!options) {
        this.screening_reasons_categories = await this.$helpers.wrapInArray(options?.reason_categories)
        this.screening_reasons = await this.$helpers.wrapInArray(options?.reasons)
        await this.setDossiersOptions(options?.dossiers_options)
      }
    },
    computed: {
      currentCompany() {
        return this.$store.getters[`companies/getCurrentCompany`]
      },
      screening_reasons_categories_filter() {
        return this.screening_reasons.filter((item) => item.category === this.form.screening.reason_categories)
      },
      packageTitle() {
        const selectedPackage = this.getSelectedPackage()
        return !!selectedPackage ? selectedPackage.title : ``
      },
      packageSteps() {
        const selectedPackage = this.getSelectedPackage()
        return !!selectedPackage ? selectedPackage.steps : ``
      },
      langLocales() {
        return this.$helpers.languagesLocales()
      },
      currentCompanyInfos() {
        return this.$store.getters[`companies/getCurrentCompanyInfos`]
      },
      titles() {
        return [
          {
            text: this.$t(`components.genders.f`),
            value: `F`
          },
          {
            text: this.$t(`components.genders.m`),
            value: `M`
          }
        ]
      },
      currentObs() {
        return this.form.currentStep === STEPS.SCREENING
          ? `screeningObs`
          : this.form.currentStep === STEPS.CANDIDATE
          ? `candidateObs`
          : this.form.currentStep === STEPS.SETTINGS
          ? `settingsObs`
          : null
      },
      candidateAlerts() {
        return this.form.settings.selected_alerts.filter((alert) => alert.code.includes(`employee_`))
      },
      otherAlerts() {
        return this.form.settings.selected_alerts.filter((alert) => alert.code.includes(`client_user_`))
      }
    },
    watch: {
      'form.settings.dueDate'(newDueDate) {
        if (!!newDueDate) {
          const date = this.$moment()
          this.reportDate = date.add(newDueDate, `days`).format(`L`)
        }
        this.reportDate = this.$t(`messages.na`)
      },
      'form.candidate.last_name': {
        deep: false,
        handler(newValue, oldValue) {
          newValue !== oldValue && (this.form.candidate.last_name = newValue.toUpperCase())
        }
      },
      form: {
        deep: true,
        handler(form) {
          if (!!this.isInitialScreeningStep) {
            this.isInitialScreeningStep =
              JSON.stringify({ screening: form.screening }) === JSON.stringify(this.initialScreeningStep())
          }
        }
      },
      defaultScreeningDeletionData: {
        handler(screeningDeletionPeriod) {
          this.form.settings.custom_data_deletion = screeningDeletionPeriod.period
        }
      },
      defaultTranparency: {
        handler(transparency) {
          this.form.settings.transp_progress = transparency.progress
          this.form.settings.transp_result = transparency.result
        }
      },
      defaultAlerts: {
        handler(alerts) {
          this.form.settings.custom_alerts = alerts.custom_alerts
          this.form.settings.selected_alerts = alerts.selected_alerts
        }
      },
      'form.currentStep': {
        handler() {
          this.saveForm()
          this.focus()
        }
      }
    },
    mounted() {
      console.log(`mounted new`)

      const savedNew = this.$store.getters[`screenings/newSaved`](this.currentCompany)
      if (savedNew !== null) {
        try {
          this.form = JSON.parse(savedNew)
        } catch (e) {
          console.error(e)
          this.$store.commit(`screenings/cleanNewSaved`, this.currentCompany)
        }
      }
      this.refresh += 1
    },

    methods: {
      setDossiersOptions(dossierOption = {}) {
        const {
          alerts,
          transparency,
          screening_deletion_period: defaultScreeningDeletionData
        } = dossierOption
        this.defaultTranparency = { ...transparency }
        this.defaultAlerts = { ...alerts }
        this.defaultScreeningDeletionData = { ...defaultScreeningDeletionData }
      },
      checkPermission() {
        const perm = this.$store.getters[`menu/getPermissionFromUrl`](this.$route.path)
        if (!!perm) {
          return this.$gates.hasPermission(perm)
        } else {
          return false
        }
      },
      saveForm() {
        this.$store.commit(`screenings/saveNew`, {
          data: JSON.stringify(this.form),
          company: this.currentCompany
        })
      },
      initialForm() {
        return {
          currentStep: STEPS.SCREENING,
          ...this.initialScreeningStep(),
          ...this.initialCandidatesStep(),
          ...this.initialSettingsStep()
        }
      },
      clearForm() {
        console.log(`clears ${this.form.currentStep} step`, this.form.currentStep)
        const currentStep = this.form.currentStep
        const initCurrentStep =
          currentStep === STEPS.SCREENING
            ? { ...this.initialScreeningStep() }
            : currentStep === STEPS.CANDIDATE
            ? { ...this.initialCandidatesStep() }
            : currentStep === STEPS.SETTINGS
            ? { ...this.initialSettingsStep() }
            : {}
        this.form = {
          ...this.form,
          ...initCurrentStep
        }
        this.saveForm()
        this.refresh += 1
      },
      clearAllForm() {
        console.log(`clears all form`)
        this.$store.commit(`screenings/cleanNewSaved`, this.currentCompany)
        this.form = this.initialForm()
        this.refresh += 1
        this.dataClearAllDialog = false
      },
      initialScreeningStep() {
        return {
          screening: {
            reason: null,
            reason_category: null,
            package: ``,
            options: {}
          }
        }
      },
      initialCandidatesStep() {
        return {
          candidate: {
            title: ``,
            first_name: ``,
            last_name: ``,
            birthdate: ``,
            job_title: ``,
            email: ``,
            phone: ``,
            lang: ``,
            cost_center: ``,
            comments: ``,
            start_date: ``,
            cv_files: undefined,
            other_files: undefined
          }
        }
      },
      initialSettingsStep() {
        return {
          settings: {
            int_report: false,
            transp_progress: false,
            transp_result: false,
            selected_reminders: undefined,
            custom_alerts: false,
            selected_alerts: {},
            dueDate: null,
            custom_data_deletion: this.defaultScreeningDeletionData?.period || 90,
            ...this.defaultAlerts
          }
        }
      },
      goToStep(idStep) {
        if (idStep > this.form.currentStep) {
          for (let n = this.form.currentStep; n < idStep; n++) {
            switch (n) {
              case STEPS.SCREENING:
                this.validScreening()
                break
              case STEPS.CANDIDATE:
                this.validCandidate()
                break
              case STEPS.SETTINGS:
                this.validSettings()
                break
            }
          }
        } else {
          this.changeCurrentStep(idStep)
        }
      },
      changeCurrentStep(idStep) {
        this.form.currentStep = idStep
      },
      focus() {
        const element = this.$refs[this.currentObs]
        if (element) {
          this.$nextTick(() => {
            const inputIsSelect = element?.$el?.getElementsByTagName(`input`)[0].hasAttribute(`autocomplete`) || false
            if (inputIsSelect) {
              setTimeout(() => {
                element?.$el?.getElementsByTagName(`input`)[0].click()
              }, 500)
            } else {
              this.$helpers.focus({
                element: element?.$el?.getElementsByTagName(`input`)
              })
            }
          })
        }
      },
      async getPackages() {
        return await this.$api.packages.me()
      },
      getSelectedPackage() {
        return this.screening_packages.find((p) => p.name === this.form.screening.package)
      },
      async getOptions() {
        return await this.$api.screeningOrder.get()
      },
      updateRules(pack) {
        const screeningPackage = !!pack ? pack : this.getSelectedPackage()
        this.cleanValidation()
        this.rules.cv_required = !!screeningPackage?.cv_required ? `required` : null
      },
      toggleOptions(options) {
        this.form.screening.options = options
      },
      getReason(reasonId) {
        if (reasonId !== undefined && reasonId !== null) {
          const reason = this.screening_reasons.find((sr) => sr.value === reasonId)
          if (!!reason) {
            return reason.text
          }
        }
        return ``
      },
      getReasonCategory(categoryId) {
        if (categoryId !== undefined && categoryId !== null) {
          const reason = this.screening_reasons_categories.find((src) => src.value === categoryId)
          if (!!reason) {
            return reason.text
          }
        }
        return ``
      },
      cleanValidation() {
        this.$refs.screeningObs?.reset()
        this.$refs?.candidateObs?.reset()
        this.$refs.settingsObs?.reset()
      },
      async validScreening() {
        await this.validStep(this.$refs.screeningObs, STEPS.CANDIDATE)
      },
      async validCandidate() {
        await this.validStep(this.$refs.candidateObs, STEPS.SETTINGS)
      },
      async validSettings() {
        await this.validStep(this.$refs.settingsObs, STEPS.SUMMARY)
      },
      async validStep(refObs, nextStep) {
        const isValid = await refObs.validate()
        if (isValid) {
          this.changeCurrentStep(nextStep)
        } else {
          this.goTo(refObs)
        }
      },
      goTo(refObs) {
        this.$vuetify.goTo(refObs, {})
      },
      changeDate(value) {
        return this.$moment().add(value, `d`)
      },
      formatAlerts(alerts) {
        return alerts.length > 0 ? Object.fromEntries(alerts.map((a) => [a.name, a.answers])) : null
      },
      formatData() {
        const { candidate, settings, ...restForm } = { ...this.form }
        const selectedAlerts = this.formatAlerts(settings.selected_alerts)

        return {
          ...restForm,
          candidate: { ...candidate, ...this.formFiles },
          settings: {
            ...settings,
            selected_alerts: selectedAlerts
          }
        }
      },
      send() {
        this.disabledButtonConfirmOrder = true
        // POST Create Screening

        const formWithFiles = this.formatData()

        this.$api.screeningOrder
          .create(formWithFiles)
          .then((res) => {
            this.infoMessage({ message: this.$t(res.message) })
            this.$store.commit(`screenings/cleanNewSaved`, this.currentCompany)
            this.$store.dispatch(`accounting/getSettings`, { companyTag: this.$auth.user.current_company })
            this.disabledButtonConfirmOrder = false
            this.$router.push({ path: `/client/` })
          })
          .catch(this.orderCatchError)
      },
      orderCatchError(err) {
        if (err?.status === 400) {
          // Form errors
          this.setError(err?.data?.form?.legal, this.$refs.termsObs, STEPS.SETTINGS)
          this.setError(err?.data?.form?.candidate, this.$refs.candidateObs, STEPS.CANDIDATE)
          this.setError(err?.data?.form?.screening, this.$refs.screeningObs, STEPS.SCREENING)
          this.setError(err?.data?.form?.settings, this.$refs.settingsObs, STEPS.SETTINGS)

          // Captcha Error
          if (!!err?.data?.captchaToken) {
            this.errorMessage({ message: this.$t(err.data.captchaToken[0]) })
          }
        } else if (err?.status === 422) {
          this.errorMessage({ message: err.data.error })
        }
        this.disabledButtonConfirmOrder = false
      },
      credits() {
        const packageObject = this.screening_packages.find((p) => p.name === this.form.screening.package)
        try {
          return Object.values(packageObject.options).reduce((acc, option) => {
            if (option.activate && !option.locked) {
              acc += option.price
            }
            return acc
          }, packageObject.price)
        } catch (e) {
          return packageObject?.price || null
        }
      },
      setError(step, stepObs, stepError) {
        if (!!step) {
          stepObs.setErrors(step)
          this.changeCurrentStep(stepError)
        }
      },
      infoMessage({ title = this.$t(`messages.screening_created`), message = `` }) {
        this.$flashMessage.info({
          title,
          message
        })
      },
      errorMessage({ title = this.$t(`messages.error`), message = this.$t(`messages.unknown_error`) }) {
        this.$flashMessage.error({
          title,
          message
        })
      }
    }
  }
